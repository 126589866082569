
import { defineComponent, ref, provide } from "vue";

export default defineComponent({
  components: {},
  setup() {
    const salesReportFilter = ref("");
    const importantNotesRef = ref<HTMLElement>();

    provide("bind[importantNotesRef]", (el: HTMLElement) => {
      importantNotesRef.value = el;
    });

    const prevImportantNotes = () => {
      const el: any = importantNotesRef.value;
      el.tns.goTo("prev");
    };
    const nextImportantNotes = () => {
      const el: any = importantNotesRef.value;
      el.tns.goTo("next");
    };

    return {
      salesReportFilter,
      prevImportantNotes,
      nextImportantNotes,
    };
  },
});
